import { FinanceToolsCategory } from '@website/types'

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/atm-instant-cash-rewards/id1507829573'
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.atm.atm.prod'
export const CUSTOMER_SERVICE_LINK =
  'https://atm.atlassian.net/servicedesk/customer/portals'
export const FEATURES_LINKS = [
  {
    name: 'Budgeting',
    description: 'Budget Smarter',
    href: '/#budgeting',
  },
  {
    name: 'Cash Advance',
    description: 'Get Cash When You Need It',
    href: '/#cash-advance',
  },
  {
    name: 'Personal Finance',
    description: 'Track Your Spending',
    href: '/#personal-finance',
  },
  {
    name: 'Rewards & Cashback',
    description: 'Earn Money',
    href: '/#rewards',
  },
]

export const COMPARISON_LINKS = [
  {
    name: 'Bank Accounts',
    short: 'Banking',
    href: '/compare/bank-account',
    title: 'Compare Bank Accounts',
    description:
      'Explore top bank account offers with no monthly fees, early direct deposit, and cash back rewards.',
    button: 'Compare Banks',
  },
  {
    name: 'Budgeting Apps',
    short: 'Budgeting',
    href: '/compare/budgeting',
    title: 'Compare Budgeting Apps',
    description:
      'Simplify your finances with apps that track spending, manage bills, and help you save smarter.',
    button: 'Compare Budgeting',
  },
  {
    name: 'Cash Advances',
    short: 'Cash Advance',
    href: '/compare/cash-advance',
    title: 'Compare Cash Advances',
    description:
      'Access paycheck advances without interest or credit checks to cover emergency expenses.',
    button: 'Compare Cash Advances',
  },
  {
    name: 'Credit Builder Accounts',
    short: 'Build Credit',
    href: '/compare/credit-builder-account',
    title: 'Compare Credit Builders',
    description:
      'Improve your credit score while building savings with flexible credit builder accounts.',
    button: 'Compare Credit Builders',
  },
  {
    name: 'Investing Apps',
    short: 'Investing',
    href: '/compare/investing',
    title: 'Compare Investing Apps',
    description:
      'Grow your wealth with beginner-friendly investing tools or advanced trading platforms.',
    button: 'Compare Investing',
  },
]

export const FINANCE_TOOLS: {
  name: string
  href: string
  description: string
  category: FinanceToolsCategory
}[] = [
  // Budgeting
  // {
  //   name: 'Savings Goal Calculator',
  //   href: '/finance-tools/budgeting/savings-goal-calculator',
  //   description:
  //     'Calculate how much you need to save regularly to reach your financial goals.',
  //   category: 'Budgeting',
  // },
  // {
  //   name: '50/30/20 Budget Calculator',
  //   href: '/finance-tools/budgeting/50-30-20-budget-calculator',
  //   description:
  //     'Break down your income according to the 50/30/20 budgeting rule for needs, wants, and savings.',
  //   category: 'Budgeting',
  // },
  // {
  //   name: 'Emergency Fund Calculator',
  //   href: '/finance-tools/budgeting/emergency-fund-calculator',
  //   description:
  //     'Determine the ideal amount for your emergency fund based on expenses.',
  //   category: 'Budgeting',
  // },
  // {
  //   name: 'Monthly Expense Calculator',
  //   href: '/finance-tools/budgeting/monthly-expense-calculator',
  //   description:
  //     'Track and calculate your monthly living expenses to maintain a sustainable budget.',
  //   category: 'Budgeting',
  // },
  // {
  //   name: 'Budget Planner',
  //   href: '/finance-tools/budgeting/budget-planner',
  //   description: 'Create a detailed budget with planned and actual expenses.',
  //   category: 'Budgeting',
  // },
  // {
  //   name: 'Cash Flow Forecast',
  //   href: '/finance-tools/budgeting/cash-flow-forecast',
  //   description:
  //     'Predict your future cash flow based on income and expenses over time.',
  //   category: 'Budgeting',
  // },

  // Debt
  {
    name: 'Loan Calculator',
    href: '/finance-tools/debt/loan-calculator',
    description:
      'Calculate monthly payments and interest for different loan types.',
    category: 'Debt',
  },
  // {
  //   name: 'Debt Payoff Calculator',
  //   href: '/finance-tools/debt/debt-payoff-calculator',
  //   description:
  //     'Estimate how long it will take to pay off debt using different strategies.',
  //   category: 'Debt',
  // },
  // {
  //   name: 'Credit Card Payoff Calculator',
  //   href: '/finance-tools/debt/credit-card-payoff-calculator',
  //   description:
  //     'Plan how to pay off your credit card debt faster and reduce interest.',
  //   category: 'Debt',
  // },
  // {
  //   name: 'Debt Consolidation Calculator',
  //   href: '/finance-tools/debt/debt-consolidation-calculator',
  //   description:
  //     'Evaluate potential savings from consolidating multiple debts into one.',
  //   category: 'Debt',
  // },
  // {
  //   name: 'Student Loan Calculator',
  //   href: '/finance-tools/debt/student-loan-calculator',
  //   description: 'Calculate monthly payments and interest on student loans.',
  //   category: 'Debt',
  // },

  // Investment
  {
    name: 'Compound Interest Calculator',
    href: '/finance-tools/investment/compound-interest-calculator',
    description:
      'Calculate how investments grow over time with compound interest.',
    category: 'Investment',
  },
  {
    name: 'ROI Calculator',
    href: '/finance-tools/investment/roi-calculator',
    description: 'Calculate the return on investment for different scenarios.',
    category: 'Investment',
  },
  // {
  //   name: 'Dollar-Cost Averaging Calculator',
  //   href: '/finance-tools/investment/dollar-cost-averaging-calculator',
  //   description:
  //     'Estimate the long-term impact of regular investments over time.',
  //   category: 'Investment',
  // },
  // {
  //   name: 'Dividend Reinvestment Calculator',
  //   href: '/finance-tools/investment/dividend-reinvestment-calculator',
  //   description:
  //     'Calculate the potential growth of reinvested dividends over time.',
  //   category: 'Investment',
  // },
  // {
  //   name: 'Asset Allocation Calculator',
  //   href: '/finance-tools/investment/asset-allocation-calculator',
  //   description:
  //     'Determine an optimal investment mix based on your risk profile.',
  //   category: 'Investment',
  // },
  // {
  //   name: 'Stock Profit Calculator',
  //   href: '/finance-tools/investment/stock-profit-calculator',
  //   description: 'Estimate profits or losses from buying and selling stocks.',
  //   category: 'Investment',
  // },

  // Retirement
  // {
  //   name: 'Retirement Calculator',
  //   href: '/finance-tools/retirement/retirement-calculator',
  //   description:
  //     'Plan for retirement by estimating savings and future income needs.',
  //   category: 'Retirement',
  // },
  // {
  //   name: 'FIRE Calculator',
  //   href: '/finance-tools/retirement/fire-calculator',
  //   description:
  //     'Calculate how long it will take to achieve financial independence and early retirement.',
  //   category: 'Retirement',
  // },
  // {
  //   name: 'Social Security Benefits Calculator',
  //   href: '/finance-tools/retirement/social-security-benefits-calculator',
  //   description:
  //     'Estimate future social security benefits based on contributions.',
  //   category: 'Retirement',
  // },
  // {
  //   name: 'Retirement Contribution Impact Calculator',
  //   href: '/finance-tools/retirement/retirement-contribution-impact-calculator',
  //   description: 'See how different contributions affect retirement savings.',
  //   category: 'Retirement',
  // },

  // Taxes
  // {
  //   name: 'Effective Tax Rate Calculator',
  //   href: '/finance-tools/taxes/effective-tax-rate-calculator',
  //   description:
  //     'Calculate the effective tax rate on your income after deductions.',
  //   category: 'Taxes',
  // },
  // {
  //   name: 'Tax Savings Calculator',
  //   href: '/finance-tools/taxes/tax-savings-calculator',
  //   description:
  //     'Estimate tax savings from contributions to retirement or health savings accounts.',
  //   category: 'Taxes',
  // },
  // {
  //   name: 'Capital Gains Tax Calculator',
  //   href: '/finance-tools/taxes/capital-gains-tax-calculator',
  //   description: 'Calculate potential capital gains taxes on investments.',
  //   category: 'Taxes',
  // },
  // {
  //   name: 'Self-Employed Tax Calculator',
  //   href: '/finance-tools/taxes/self-employed-tax-calculator',
  //   description:
  //     'Estimate taxes owed for self-employed income, factoring in deductions.',
  //   category: 'Taxes',
  // },

  // Real Estate
  // {
  //   name: 'Mortgage Calculator',
  //   href: '/finance-tools/real-estate/mortgage-calculator',
  //   description:
  //     'Calculate monthly mortgage payments based on loan terms and interest rates.',
  //   category: 'Real Estate',
  // },
  // {
  //   name: 'Rent vs. Buy Calculator',
  //   href: '/finance-tools/real-estate/rent-vs-buy-calculator',
  //   description: 'Compare the long-term costs of renting vs. buying a home.',
  //   category: 'Real Estate',
  // },
  // {
  //   name: 'Home Affordability Calculator',
  //   href: '/finance-tools/real-estate/home-affordability-calculator',
  //   description:
  //     'Determine how much house you can afford based on income and expenses.',
  //   category: 'Real Estate',
  // },
  // {
  //   name: 'Property Investment ROI Calculator',
  //   href: '/finance-tools/real-estate/property-investment-roi-calculator',
  //   description:
  //     'Evaluate the return on investment from rental or real estate purchases.',
  //   category: 'Real Estate',
  // },

  // Savings and Returns
  {
    name: 'Inflation Impact Calculator',
    href: '/finance-tools/savings-and-returns/inflation-impact-calculator',
    description:
      'See how inflation affects the future value of money and savings.',
    category: 'Savings and Returns',
  },
  // {
  //   name: 'Time Value of Money Calculator',
  //   href: '/finance-tools/savings-and-returns/time-value-of-money-calculator',
  //   description:
  //     'Calculate the present or future value of a sum of money based on interest rates.',
  //   category: 'Savings and Returns',
  // },
  // {
  //   name: 'Future Value Calculator',
  //   href: '/finance-tools/savings-and-returns/future-value-calculator',
  //   description: 'Estimate the future value of investments or savings.',
  //   category: 'Savings and Returns',
  // },
  // {
  //   name: 'Compound Savings Calculator',
  //   href: '/finance-tools/savings-and-returns/compound-savings-calculator',
  //   description: 'Estimate savings growth over time using compound interest.',
  //   category: 'Savings and Returns',
  // },

  // Personal Finance Planning
  // {
  //   name: 'Net Worth Calculator',
  //   href: '/finance-tools/personal-finance-planning/net-worth-calculator',
  //   description:
  //     'Calculate your net worth by subtracting liabilities from assets.',
  //   category: 'Personal Finance Planning',
  // },
  // {
  //   name: 'Opportunity Cost Calculator',
  //   href: '/finance-tools/personal-finance-planning/opportunity-cost-calculator',
  //   description:
  //     'Evaluate the cost of missed financial opportunities or decisions.',
  //   category: 'Personal Finance Planning',
  // },
  // {
  //   name: 'Break-Even Analysis Calculator',
  //   href: '/finance-tools/personal-finance-planning/break-even-analysis-calculator',
  //   description:
  //     'Calculate the break-even point for investments or business ventures.',
  //   category: 'Personal Finance Planning',
  // },
  // {
  //   name: 'Financial Independence Calculator',
  //   href: '/finance-tools/personal-finance-planning/financial-independence-calculator',
  //   description: 'Estimate when you can achieve financial independence.',
  //   category: 'Personal Finance Planning',
  // },

  // Tools and Resources
  // {
  //   name: 'Currency Converter',
  //   href: '/finance-tools/tools-and-resources/currency-converter',
  //   description: 'Convert currencies using the latest exchange rates.',
  //   category: 'Tools and Resources',
  // },
  // {
  //   name: 'Loan Comparison Tool',
  //   href: '/finance-tools/tools-and-resources/loan-comparison-tool',
  //   description: 'Compare loan offers to find the best option for your needs.',
  //   category: 'Tools and Resources',
  // },
  // {
  //   name: 'Income-to-Debt Ratio Calculator',
  //   href: '/finance-tools/tools-and-resources/income-to-debt-ratio-calculator',
  //   description:
  //     'Calculate your income-to-debt ratio to evaluate creditworthiness.',
  //   category: 'Tools and Resources',
  // },
  // {
  //   name: 'Retirement Readiness Score',
  //   href: '/finance-tools/tools-and-resources/retirement-readiness-score',
  //   description: 'Get a score indicating how prepared you are for retirement.',
  //   category: 'Tools and Resources',
  // },
]
