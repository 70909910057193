import Link from 'next/link'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { LogoWhite } from '@ui/components/Svg'
import { SocialIcons } from '@ui/components/SocialIcons'
import { FooterDisclosure } from '@ui/components/Disclosure'

export function Footer({
  className,
  isLoggedIn = false,
}: {
  className?: string
  isLoggedIn?: boolean
}) {
  return (
    <footer className={clsx('bg-blueberry-1000 text-white', className)}>
      <Container>
        <div className="flex flex-col gap-8 py-12 lg:gap-0 lg:py-20">
          <div className="flex flex-col items-center justify-between gap-8 lg:flex-row lg:gap-0">
            <div className="flex w-full flex-grow flex-col gap-6 text-sm lg:w-2/3 lg:flex-1">
              <div>
                <LogoWhite className="h-10 w-[135px]" />
              </div>
              <div className="body-3 font-semibold">
                Copyright &copy; {new Date().getFullYear()} ATM.com
                <sup>&reg;</sup> | All rights reserved.
                <br />
                None of your data will ever be used without your permission.
              </div>
              <div className="border-blueberry-100 flex space-x-4 border-b pb-8 lg:border-b-0 lg:pb-0">
                <SocialIcons className="flex gap-5" />
              </div>
            </div>
            <div className="body-4 w-full font-bold lg:mb-8 lg:w-auto">
              <div className="grid grid-cols-2 items-center gap-4 xl:grid-cols-3">
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/about/"
                >
                  About
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/faq/"
                  prefetch={false}
                >
                  FAQ
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/press/"
                >
                  Press
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/compare"
                >
                  Comparisons
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/best-budgeting-apps"
                >
                  Budgeting
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/best-cash-advance-apps"
                >
                  Cash Advance
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/colony-guides"
                >
                  Colony Guides
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/careers/"
                >
                  Careers
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/contact-us/"
                  prefetch={false}
                >
                  Contact Us
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/sweepstakes/"
                >
                  Sweepstakes Rules
                </Link>
                <Link
                  href="/streakstakes/"
                  className="hover:text-blueberry-200 transition-colors"
                >
                  Streakstakes Rules
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/legal-disclaimers/"
                >
                  Legal/Disclaimers
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/privacy-policy/"
                >
                  Privacy Policy
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/terms-of-service/"
                >
                  Terms of Service
                </Link>
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/privacy-policy/#California-Privacy-Rights"
                >
                  Do Not Sell My Personal Information
                </Link>
                {isLoggedIn && (
                  <Link
                    className="hover:text-blueberry-200 transition-colors"
                    href="/auth/logout"
                  >
                    Logout
                  </Link>
                )}
              </div>
            </div>
          </div>
          <FooterDisclosure />
          <p className="content-disclosure text-left lg:mt-6">
            ATM.com, Inc., P.O. Box 7475, Newport Beach, CA 92658-9998
          </p>
        </div>
      </Container>
    </footer>
  )
}
